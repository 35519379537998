<template>
  <body>
    <div class="printeighty">
      <!--店信息-->
      <h1>{{ shopInfoData.Name }}</h1>
      <p
        v-if="
          shopInfoData.ShopBossName != null && shopInfoData.ShopBossName != ''
        "
      >
        {{ shopInfoData.ShopBossName }}
      </p>

      <p v-if="shopInfoData.TaxNumber != null && shopInfoData.TaxNumber != ''">
        {{ shopInfoData.TaxNumber }}
      </p>

      <p v-if="shopInfoData.Location != null && shopInfoData.Location != ''">
        {{ shopInfoData.Location }}
      </p>
      <p>
        <span v-if="shopInfoData.ZipCode != null && shopInfoData.ZipCode != ''">
          {{ shopInfoData.ZipCode }}/
        </span>
        <span v-if="shopInfoData.City != null && shopInfoData.City != ''"
          >{{ shopInfoData.City }}
        </span>
      </p>

      <p
        v-if="
          shopInfoData.PhoneNumber != null && shopInfoData.PhoneNumber != ''
        "
      >
        {{ shopInfoData.PhoneNumber }}
      </p>

      <hr />

      <div style="display: flex">
        <div style="width: calc(100% - 100px)">
          <!--客户信息 Nombre Cliente-->
          <b style="font-size: 10pt">{{
            tools.GetPrintLanguageValue("web.顾客")
          }}</b>
          <p>
            {{ tools.GetPrintLanguageValue("web.名称") }}:{{
              repairInfoData.ClientInfo.Name
            }}
          </p>
          <p
            v-if="
              repairInfoData.ClientInfo.Address === undefined ||
              repairInfoData.ClientInfo.Address === ''
            "
          >
            No hay dirección
          </p>
          <p v-if="repairInfoData.ClientInfo.Address !== undefined">
            {{ repairInfoData.ClientInfo.Address }}
          </p>
          <p
            v-if="
              repairInfoData.ClientInfo.DocNumber != null &&
              repairInfoData.ClientInfo.DocNumber != ''
            "
          >
            <span class="uppercase"
              >{{ repairInfoData.ClientInfo.DocTypeName }}
            </span>
            {{ repairInfoData.ClientInfo.DocNumber }}
          </p>
          <p>
            {{ tools.GetPrintLanguageValue("web.手机号码") }}.{{
              repairInfoData.ClientInfo.PhoneNumber
            }}
          </p>
        </div>
        <div style="width: 100px">
          <el-image :src="qrcodeImage" style="width: 80px; margin: 0 auto">
          </el-image>
        </div>
      </div>

      <!--维修信息-->

      <div v-for="item in repairInfoData.RepairMobileInfos" :key="item.Id + 64">
        <hr />
        <b style="font-size: 11pt">{{
          tools.GetPrintLanguageValue("web.维修")
        }}</b>
        <!--  reparación -->
        <div class="fl-grow">
          <p
            v-if="
              item.RepairStartTimeFormat != '' &&
              item.RepairStartTimeFormat != null
            "
          >
            {{ tools.GetPrintLanguageValue("web.维修时间") }}:
            {{ item.RepairStartTimeFormat }}
          </p>
          <!-- <p v-if="item.ModelName != '' && item.ModelName != null">
            {{ item.ModelName }}
          </p> -->
          <p v-if="item.ImelNumber != '' && item.ImelNumber != null">
            IMEI/SN:{{ item.ImelNumber }}
          </p>

          <p>
            {{ tools.GetPrintLanguageValue("web.品牌") }}/{{
              tools.GetPrintLanguageValue("web.型号")
            }}:{{ item.BrandAndModel }} {{ item.ModelPropertiesFormat }}
          </p>
          <p v-if="item.Password != null && item.Password != ''">
            {{ tools.GetPrintLanguageValue("web.密码") }}:{{ item.Password }}
          </p>
          <!-- <p v-if="item.Budget > 0">
            {{ tools.GetPrintLanguageValue("web.预算") }}:{{ item.BudgetFormat }}
          </p> -->

          <p
            v-if="item.Budget > 0"
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 3px;
            "
          >
            <span style="font-size: 10pt">
              <b> {{ tools.GetPrintLanguageValue("web.预算") }}</b>
            </span>
            <span style="font-size: 11pt">
              <b> {{ item.BudgetFormat }} </b>
            </span>
          </p>
          <p
            v-if="item.Downpayment > 0"
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 3px;
            "
          >
            <span style="font-size: 10pt">
              <b> {{ tools.GetPrintLanguageValue("web.定金") }}</b>
            </span>
            <span style="font-size: 11pt">
              <b> {{ item.DownpaymentFormat }} </b>
            </span>
          </p>

          <p>{{ item.Comment }}</p>
        </div>
        <hr />
        <table width="100%" style="font-size: 8pt">
          <tr>
            <td></td>
            <td style="width: 40px">
              {{ tools.GetPrintLanguageValue("web.数量") }}
            </td>
            <td style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.单价") }}
            </td>
            <!-- <th style="width: 60px" v-if="shopInfoData.PrintMaintainTaxRate">
                {{ tools.GetPrintLanguageValue("web.税率") }}
              </th> -->
            <td style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.合计") }}
            </td>
          </tr>

          <tr v-for="(data, index) in item.RepairItems" :key="index">
            <td>
              <span class="fl-grow">{{ data.Name }}</span>
            </td>
            <td>{{ data.Number }}</td>
            <td>{{ data.PriceFormat }}</td>
            <td>{{ data.TotalFormat }}</td>
          </tr>
        </table>
      </div>

      <!-- 定金 预算-->
      <hr v-if="shopInfoData.PrintMaintainTaxRate" />

      <table
        width="100%"
        style="font-size: 8pt"
        v-if="shopInfoData.PrintMaintainTaxRate"
      >
        <tr>
          <td style="width: 40px">
            {{ tools.GetPrintLanguageValue("web.金额") }}
          </td>
          <td style="width: 60px">
            {{ tools.GetPrintLanguageValue("web.税率") }}
          </td>
          <td style="width: 60px">
            {{ tools.GetPrintLanguageValue("web.税额") }}
          </td>
          <td style="width: 60px">
            {{ tools.GetPrintLanguageValue("web.合计") }}
          </td>
        </tr>

        <tr v-for="(data, index) in repairInfoData.TaxRates" :key="index">
          <td>
            {{ data.AmountFormat }}
          </td>
          <td>{{ data.TaxRate }}</td>
          <td>{{ data.TaxAmountFormat }}</td>
          <td>{{ data.TotalFormat }}</td>
        </tr>
      </table>

      <hr />

      <div class="fl-layout">
        <p class="fl-grow" style="font-size: 10pt">
          <b> {{ tools.GetPrintLanguageValue("web.合计") }} </b>
        </p>
        <p class="fl-not" style="font-size: 11pt">
          <b>
            {{ repairInfoData.TotalFormat }}
          </b>
        </p>
      </div>

      <div class="barcode-wrapper">
        <svg id="barcode"></svg>
      </div>

      <hr />
      <p v-if="!settingModel.DotPrintDrawer">
        <!-- Atendido por:-->
        {{ tools.GetPrintLanguageValue("web.操作人") }}:{{ operatorName }}
      </p>
      <br />

      <div v-html="printAndTermData.ConditionsOfRepair"></div>

      <br />
      <div class="fl-layout">
        <div class="fl-grow">
          <!-- <h3>Firma &#x2F; Sello Establecimiento</h3> -->
          <h3>
            {{ tools.GetPrintLanguageValue("web.签名") }} &#x2F;
            {{ tools.GetPrintLanguageValue("web.盖章") }}
          </h3>

          <br /><br />
          <div class="print-signature-box"></div>
        </div>
      </div>

      <br />
      <br />
      <div class="change-page"></div>

      <div v-if="!settingModel.DotPrintCounterfoil">
        <!--店信息-->
        <h1>{{ shopInfoData.Name }}</h1>

        <p
          v-if="
            shopInfoData.ShopBossName != null && shopInfoData.ShopBossName != ''
          "
        >
          {{ shopInfoData.ShopBossName }}
        </p>

        <p
          v-if="shopInfoData.TaxNumber != null && shopInfoData.TaxNumber != ''"
        >
          {{ shopInfoData.TaxNumber }}
        </p>

        <p v-if="shopInfoData.Location != null && shopInfoData.Location != ''">
          {{ shopInfoData.Location }}
        </p>
        <p>
          <span
            v-if="shopInfoData.ZipCode != null && shopInfoData.ZipCode != ''"
          >
            {{ shopInfoData.ZipCode }}/
          </span>
          <span v-if="shopInfoData.City != null && shopInfoData.City != ''"
            >{{ shopInfoData.City }}
          </span>
        </p>

        <p
          v-if="
            shopInfoData.PhoneNumber != null && shopInfoData.PhoneNumber != ''
          "
        >
          {{ shopInfoData.PhoneNumber }}
        </p>

        <hr />

        <!--客户信息-->
        <!-- <h3>Cliente</h3>
        <p>Nombre:{{ repairInfoData.ClientInfo.Name }}</p> -->
        <b style="font-size: 10pt">{{
            tools.GetPrintLanguageValue("web.顾客")
          }}</b>
        <p>
          {{ tools.GetPrintLanguageValue("web.名称") }}:{{
            repairInfoData.ClientInfo.Name
          }}
        </p>
        <p
          v-if="
            repairInfoData.ClientInfo.Address === undefined ||
            repairInfoData.ClientInfo.Address === ''
          "
        >
          No hay dirección
        </p>
        <p v-if="repairInfoData.ClientInfo.Address !== undefined">
          {{ repairInfoData.ClientInfo.Address }}
        </p>
        <p
          v-if="
            repairInfoData.ClientInfo.DocNumber != null &&
            repairInfoData.ClientInfo.DocNumber != ''
          "
        >
          <span class="uppercase"
            >{{ repairInfoData.ClientInfo.DocTypeName }}
          </span>
          {{ repairInfoData.ClientInfo.DocNumber }}
        </p>
        <p>
          {{ tools.GetPrintLanguageValue("web.手机号码") }}.{{
            repairInfoData.ClientInfo.PhoneNumber
          }}
        </p>

        <!--维修信息-->

        <div
          v-for="item in repairInfoData.RepairMobileInfos"
          :key="item.Id + 64"
        >
          <hr />
          <!-- <h3>reparación</h3> -->
          <b style="font-size: 11pt">{{
          tools.GetPrintLanguageValue("web.维修")
        }}</b>

          <div class="fl-grow">
            <p
              v-if="
                item.RepairStartTimeFormat != '' &&
                item.RepairStartTimeFormat != null
              "
            >
              {{ tools.GetPrintLanguageValue("web.维修时间") }}:
              {{ item.RepairStartTimeFormat }}
            </p>
            <p>{{ item.ModelName }}</p>
            <p v-if="item.ImelNumber != '' && item.ImelNumber != null">
              IMEI/SN:{{ item.ImelNumber }}
            </p>
            <p>
              {{ tools.GetPrintLanguageValue("web.品牌") }}/{{
                tools.GetPrintLanguageValue("web.型号")
              }}:{{ item.BrandAndModel }} {{ item.ModelPropertiesFormat }}
            </p>
            <p v-if="item.Password != '' && item.Password != null">
              {{ tools.GetPrintLanguageValue("web.密码") }}:{{ item.Password }}
            </p>
          
            <p
            v-if="item.Budget > 0"
            style="display: flex; justify-content: space-between;margin-top: 3px;"
          >
          <span style="font-size: 10pt">
            <b>   {{ tools.GetPrintLanguageValue("web.预算") }}</b>
            </span>
            <span style="font-size: 11pt">
              <b>  {{ item.BudgetFormat }} </b>
            </span>
          </p> 
          <p
            v-if="item.Downpayment > 0"
            style="display: flex; justify-content: space-between;margin-top: 3px;"
          >
          <span style="font-size: 10pt">
            <b>    {{ tools.GetPrintLanguageValue("web.定金") }}</b>
            </span>
            <span style="font-size: 11pt">
              <b>   {{ item.DownpaymentFormat }}  </b>
            </span>
          </p>


            <p>{{ item.Comment }}</p>
          </div>
          <hr />
          <table width="100%" style="font-size: 8pt">
            <tr>
              <th></th>
              <th style="width: 40px">
                {{ tools.GetPrintLanguageValue("web.数量") }}
              </th>
              <th style="width: 60px">
                {{ tools.GetPrintLanguageValue("web.单价") }}
              </th>
              <!-- <th style="width: 60px" v-if="shopInfoData.PrintMaintainTaxRate">
                {{ tools.GetPrintLanguageValue("web.税率") }}
              </th> -->
              <th style="width: 60px">
                {{ tools.GetPrintLanguageValue("web.合计") }}
              </th>
            </tr>

            <tr v-for="(data, index) in item.RepairItems" :key="index">
              <td>
                <span class="fl-grow">{{ data.Name }}</span>
              </td>
              <td>{{ data.Number }}</td>
              <td>{{ data.PriceFormat }}</td>
              <!-- <td v-if="shopInfoData.PrintMaintainTaxRate">
                {{ data.TaxRate }}
              </td> -->
              <td>{{ data.TotalFormat }}</td>
            </tr>
          </table>
        </div>

        <!-- 定金 预算-->
        <hr v-if="shopInfoData.PrintMaintainTaxRate" />

        <table
          width="100%"
          style="font-size: 8pt"
          v-if="shopInfoData.PrintMaintainTaxRate"
        >
          <tr>
            <td style="width: 40px">
              {{ tools.GetPrintLanguageValue("web.金额") }}
            </td>
            <td style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.税率") }}
            </td>
            <td style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.税额") }}
            </td>
            <td style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.合计") }}
            </td>
          </tr>

          <tr v-for="(data, index) in repairInfoData.TaxRates" :key="index">
            <td>
              {{ data.AmountFormat }}
            </td>
            <td>{{ data.TaxRate }}</td>
            <td>{{ data.TaxAmountFormat }}</td>
            <td>{{ data.TotalFormat }}</td>
          </tr>
        </table>

        <hr />

        <div class="fl-layout">
        <p class="fl-grow" style="font-size: 10pt">
          <b> {{ tools.GetPrintLanguageValue("web.合计") }} </b>
        </p>
        <p class="fl-not" style="font-size: 11pt">
          <b>
            {{ repairInfoData.TotalFormat }}
          </b>
        </p>
      </div>

        <hr />
        <div class="barcode-wrapper">
          <svg id="barcode"></svg>
        </div>

        <hr />
        <p v-if="!printAndTermData.DotPrintDrawer">
          {{ tools.GetPrintLanguageValue("web.操作人") }}: {{ operatorName }}
        </p>
        <br />

        <div v-html="printAndTermData.ConditionsOfRepair"></div>
        <br />
        <div class="fl-layout">
          <div class="fl-grow">
            <!-- <h3>Firma</h3> -->
            <h3>{{ tools.GetPrintLanguageValue("web.签名") }}</h3>
            <br /><br />
            <div class="print-signature-box"></div>
          </div>
          <div class="fl-span-10px"></div>
          <div class="fl-grow">
            <!-- <h3>Recogido</h3> -->
            <h3>{{ tools.GetPrintLanguageValue("web.盖章") }}</h3>

            <br /><br />
            <div class="print-signature-box"></div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script setup>
import "../PrintCss/print.css";
import "../PrintCss/printeighty.css";
import "../PrintCss/custom-flex.css";

import { ref, onMounted } from "vue";
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
import { useRoute } from "vue-router";
const route = useRoute();
const currentRepairId = ref("");
// 店铺信息
const shopInfoData = ref({
  Name: "维修侠", // 店铺名称
  Address: "四川成都", // 地址
  NieOrCifNumber: "",
  TelephoneNo: "0811-12543574", // 联系电话
});
// 客户信息
const clientInfoData = ref({
  Name: "客户姓名",
  Address: "很长很长的地址一大串啊啊啊啊啊啊啊啊",
  DocTypeName: "DNI", // 证件类型名称
  DocNumber: "528485A", // 证件类型名称
  PhoneNumber: "0325-52143",
});
const repairItemDataInfo = ref([
  {
    Name: "设备进水", // 维修项名称
    Number: 1, // 数量
    Price: 2.0, // 单价
    TotalPrice: 2.0, // 总价
  },
  {
    Name: "更换屏幕", // 维修项名称
    Number: 1, // 数量
    Price: 4.0, // 单价
    TotalPrice: 4.0, // 总价
  },
]); // 维修的项目
const totalAllPrice = ref(6.0); // 统计的价格
// 维修单信息
// const repairInfoDatas= ref([]);
const repairInfoData = ref({
  ClientInfo: {},
});
// 当前操作员名称
const operatorName = ref("");
// 打印设置数据
const printAndTermData = ref({
  Id: undefined,
  DotPrintDrawer: false,
  ReserveTerm: "",
  ConditionsOfRepair: "",
  WarrantyTerms: "<p>This is <strong>rich text</strong> content.</p>", // 保修条款
  Remark: "",
});

const settingModel = ref({
  DotPrintCounterfoil: localStorage.getItem("DotPrintCounterfoil") == "yes",
  DotPrintDrawer: localStorage.getItem("DotPrintDrawer") == "yes",
});

const qrcodeImage = ref("");

// 页面加载
onMounted(() => {
  currentRepairId.value = route.query.id;
  console.log("当前repairId" + currentRepairId.value);

  getRepairInfo(); // 维修信息
  loadPrintSetting(); // 打印条款

  loadOtherSetting();
  loadCurrentUserName();
});
const showPrintPage = () => {
  console.log("The component is now mounted.");
  setTimeout(() => {
    window.print();
    setTimeout(() => {
      closeCurrentPage();
    }, 500);
  }, 1500);
};
const initone = () => {
  if (repairInfoData.value.RepairNo) {
    const ts = parseInt(repairInfoData.value.RepairNo.valueOf(), 10);
    JsBarcode("#barcode", ts, {
      width: 2,
      height: 25,
      fontSize: 12,
    });

    // 获取当前网址
    var url = `${window.location.origin}/repairSchedule?repairId=${repairInfoData.value.Id}`;

    QRCode.toDataURL(url, (err, url) => {
      qrcodeImage.value = url;
    });
  }
};
// 获取打印设置
const loadPrintSetting = () => {
  axios.apiMethod(
    "/stores/Setting/GetPrintSettingByLanguageId",
    "get",
    {},
    (result) => {
      printAndTermData.value = result.Data;
    }
  );
};

// 获取维修单信息
const getRepairInfo = () => {
  var printLanguageId = localStorage.getItem("PrintLanguageId");
  axios.apiMethod(
    "/stores/Repair/GetRepairByIdToLanguageId",
    "get",
    {
      Id: currentRepairId.value,
      languageId: printLanguageId,
    },
    (result) => {
      console.log(result);
      repairInfoData.value = result.Data;
      initone();
       showPrintPage();
    }
  );
};

// 获取其它设置
const loadOtherSetting = () => {
  axios.apiMethod("/stores/Setting/GetStoreSettings", "get", {}, (result) => {
    shopInfoData.value = result.Data;
  });
};
// 获取当前登陆人的姓名
const loadCurrentUserName = () => {
  var json = localStorage.getItem("UserInfo");
  var userInfo = JSON.parse(json);
  operatorName.value = userInfo.Name;
};
const closeCurrentPage = () => {
  window.close();
};
</script>
<style scoped>
.app_conetnt {
  box-shadow: 0 0 0px rgba(0, 0, 0, 0); /* 设置新的 box-shadow 属性 */
}
</style>
